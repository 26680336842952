@tailwind base;
@tailwind components;
@tailwind utilities;

aside .active {
  @apply bg-gray-200;
  @apply font-bold;
}

tbody tr td:nth-child(even) {
  @apply bg-gray-100;
}

.side-bar-height {
  height: 93vh;
  background-color: #E3E3E3 !important;
}

.nav-bar-height {
  height: 5vh;
}

.outlet-part {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: none !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.invisible {
  display: none;
}

nav {
  background-color: #F4F4F4 !important;
  box-shadow: 0px 0px 1px 0px #000 !important;
}

aside {
  height: 100vh;
}

aside>div {
  padding-top: 0px !important;
  /* background-color: #E3E3E3 !important; */
  box-shadow: 0px 0px 5px 0px #c5c5c5;
  height: 100vh;
  border-radius: 0px !important;
}